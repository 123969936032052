import React, { useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import ReactPaginate from "react-paginate";

const CreateHub = () => {
  const [name, setName] = useState("");
  const [name1, setName1] = useState("");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [editData, setEditData] = useState({});
  const [update, setUpdate] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const handleClose = () => setShow(false);
  const handleEditClose = () => setEditShow(false);

  const handleEditShow = (row) => {
    setEditShow(true);
    setEditData(row);
  };

  const validateName = (name) => {
    const re = /^[A-Za-z\s]+$/;
    return re.test(name);
  };

  const getCreatehub = () => {
    axios
      .get("https://coorgtour.in/api/admin/getCreatehub")
      .then(function (response) {
        setData(response.data.Createhub);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getCreatehub();
  }, []);

  const addCreatehub = async () => {
    if (!validateName(name)) {
      return alert("Please enter a valid name (letters and spaces only)");
    }
    try {
      const config = {
        url: "/addCreatehub",
        method: "post",
        baseURL: "https://coorgtour.in/api/admin",
        headers: { "Content-type": "application/json" },
        data: { name },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert("Details Added");
          handleClose();
          setName("");
          getCreatehub();
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to add Details");
    }
  };

  const editCreatehub = async () => {
    try {
      const config = {
        url: "/editCreatehub",
        method: "put",
        baseURL: "https://coorgtour.in/api/admin",
        headers: { "Content-type": "application/json" },
        data: {
          id: update?._id,
          name: name1,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert("Hub Updated");
          handleEditClose();
          setName1("");
          getCreatehub();
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to update Details");
    }
  };

  const deleteCreatehub = async (id) => {
    axios({
      method: "post",
      url: `https://coorgtour.in/api/admin/deleteCreatehub/${id}`,
    })
      .then(function (response) {
        alert(response.data.Success);
        getCreatehub();
      })
      .catch(function (error) {
        console.log(error.response.data);
      });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const [Slno, setSlno] = useState(1);
  const customTotal = (from, to, size) => {
    setSlno(from);
    return (
      <></>
      // <span className="react-bootstrap-table-pagination-total">
      //   Showing {from} to {to} of {size} Results
      // </span>
    );
  };
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: data.length,
      },
    ],
    // A numeric array is also available. the purpose of above example is custom the text
  };


  const columns = [
    {
      dataField: "name",
      text: "Sl.No",
      formatter: (value, row, i) => <p>{currentPage * pageSize + i + 1}</p>,
      headerStyle: { backgroundColor: "#080874", color: "white" },
    },
    {
      dataField: "name",
      text: "Hub Name",
      formatter: (value, row) => <p>{row.name ? row.name : ""}</p>,
      headerStyle: { backgroundColor: "#080874", color: "white" },
    },
    {
      dataField: "Delete",
      text: "Action",
      formatter: (value, row) => (
        <>
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setUpdate(row);
              handleEditShow(row);
            }}
          >
            <i className="fas fa-pen-nib" style={{ color: "#3B707F" }}></i>
          </button>
          /
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => deleteCreatehub(row._id)}
          >
            <i className="fas fa-trash" style={{ color: "#080874" }}></i>
          </button>
        </>
      ),
      headerStyle: { backgroundColor: "#080874", color: "white" },
    },
  ];


  return (
    <div style={{ paddingLeft: "30px" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
          color: "#080874",
        }}
      >
        Create Hub
      </div>

      <Container>
        <div style={{ overflow: "hidden", overflowY: "auto" }}>
          <ToolkitProvider
            keyField="id"
            data={data.slice().reverse()}
            columns={columns}
            search
            exportCSV
          >
            {(props) => (
              <div>
                <div className="d-flex">
                  <SearchBar
                    {...props.searchProps}
                    style={{ width: "300px" }}
                  />
                  <FaSearch
                    style={{
                      position: "absolute",
                      marginTop: "14px",
                      marginLeft: "270px",
                    }}
                  />
                  &nbsp;
                  <Button
                    variant=""
                    onClick={() => setShow(true)}
                    style={{
                      right: "20px",
                      position: "absolute",
                      background: "#080874",
                      color: "white",
                    }}
                  >
                    + Create Hub
                  </Button>
                </div>
                <hr />
                <br />
                <div style={{ overflowX: "scroll" }}>
                  <BootstrapTable
                    striped
                    hover
                    condensed
                    {...props.baseProps}
                    // {...props.baseProps}
                    pagination={paginationFactory(options)}
                  />
                </div>
                
              </div>
            )}
          </ToolkitProvider>
        </div>

        {/* Add Modal */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title
              style={{ fontWeight: 600, fontSize: "22px", color: "#080874" }}
            >
              Create Hub
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="urban-o mb-2">
              <label>Hub Name</label>
              <br />
              <input
                placeholder="Hub Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              style={{ border: "1px solid #080874", color: "#080874" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant=""
              onClick={addCreatehub}
              style={{ background: "#080874", color: "white" }}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Edit Modal */}
        <Modal show={editShow} onHide={handleEditClose}>
          <Modal.Header closeButton>
            <Modal.Title
              style={{ fontWeight: 600, fontSize: "22px", color: "#080874" }}
            >
              Edit Hub
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="urban-o mb-2">
              <label>Hub Name</label>
              <br />
              <input
                placeholder={editData?.name}
                onChange={(e) => setName1(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              onClick={handleEditClose}
              style={{ border: "1px solid #080874", color: "#080874" }}
            >
              Cancel
            </Button>
            <Button
              variant=""
              onClick={() => editCreatehub(editData)}
              style={{ background: "#080874", color: "white" }}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default CreateHub;
