import React, { useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import Table from "react-bootstrap/Table";

const Commission = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [datacommission, setDatacommission] = useState([]);

  const getCommission = async () => {
    try {
      let res = await fetch("https://coorgtour.in/api/admin/getcommission");
      let data = await res.json();
      setDatacommission(data.success);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCommission();
  }, []);

  const [amount, setAmount] = useState("");
  const [percentage, setpercentage] = useState("");

  const addCommission = async () => {
    try {
      let config = {
        url: "/addcommission",
        method: "post",
        baseURL: "https://coorgtour.in/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          seramt: amount,
          productpr: percentage,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Commission added successfully");
        getCommission();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [edit, setEdit] = useState({});
  const updateCommission = async () => {
    try {
      let config = {
        url: `/updatecommission/${edit?._id}`,
        method: "put",
        baseURL: "https://coorgtour.in/api/admin",
        headers: { "content-type": "application/json" },
        data: {
          // seramt: amount,
          productpr: percentage,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Commission updated successfully");
        getCommission();
        setAmount('');
        setpercentage('');
        handleClose1();
      }
    } catch (error) {
      console.log(error);

    }
  };

  return (
    <div style={{ paddingLeft: "30px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            backgroundColor: "white",
            fontWeight: 600,
            padding: "22px",
            fontSize: "22px",
            color: "#080874",
          }}
        >
          Commission
        </div>
        {
            datacommission.length < 1 &&(
                <Button
                style={{ height: "40px", marginTop: " 21px" }}
                onClick={handleShow}
              >
                Add Commission
              </Button>
            )
        }
       
      </div>
      <Container>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No.</th>
              {/* <th>Service Commission</th> */}
              <th>Product Commission</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {datacommission.map((ele, i) => {
              return (
                <tr>
                  <td>{++i}</td>
                  {/* <td>₹ {ele?.seramt}</td> */}
                  <td>{ele?.productpr} %</td>
                  <td>
                    <button
                      onClick={() => {
                        handleShow1();
                        setEdit(ele);
                      }}
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      <i
                        class="fas fa-pen-nib"
                        style={{ color: "#3B707F" }}
                      ></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Commission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="urban-o mb-2">
            <label>Service commission</label>
            <br />
            <input
              placeholder="commission in amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div> */}
          <div className="urban-o mb-2">
            <label>Product commission</label>
            <br />
            <input
              placeholder="commission in percentage"
              value={percentage}
              onChange={(e) => setpercentage(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={addCommission}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update Commission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="urban-o mb-2">
            <label>Service commission</label>
            <br />
            <input
              placeholder={edit?.seramt}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div> */}
          <div className="urban-o mb-2">
            <label>Product commission</label>
            <br />
            <input
              placeholder={edit?.productpr}
              value={percentage}
              onChange={(e) => setpercentage(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={updateCommission}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Commission;
