import { useState, useEffect } from "react";

const useDateFilter = (initialData, dateField) => {
  const [filteredData, setFilteredData] = useState(initialData);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    setFilteredData(initialData);
  }, [initialData]);

  const filterData = () => {
    if (!startDate) return alert("Please select from date");
    if (!endDate) return alert("Please select to date");

    const filtered = initialData.filter((item) => {
      const itemDate = new Date(item[dateField]);
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      return itemDate >= startDateObj && itemDate <= endDateObj;
    });
    setFilteredData(filtered);
  };

  return {
    filteredData,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    filterData,
  };
};

export default useDateFilter;
