import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import axios from "axios";
import exportFromJSON from "export-from-json";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import useDateFilter from "./Datefilter";
import SelectSearch from "react-select-search";
import { Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function Order() {
  const Input = styled("input")({
    display: "none",
  });
  const [show5, setShow5] = useState(false);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const navigator = useNavigate();
  const [Slno, setSlno] = useState(1);
  const [data, setdata] = useState([]);
console.log(data,"data")
  const pickcode = data.map((O) => O.pincode);
  // console.log("checkpincode data", data);
  // const serviceAmountotal = data?.serviceType?.reduce((accumulator ,item)=>accumulator+ item.price,0 )
  // console.log(serviceAmountotal,"serviceAmountotal")
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [editshow, seteditShow] = useState(false);
  const [editdata, seteditdata] = useState({});

  const getAllJobs = () => {
    axios
      .get("https://coorgtour.in/api/admin/getAllJobs")
      .then(function (res) {
        setdata(res.data.success);

        // console.log(res.data.success);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [selData, setselData] = useState({});
  const [AllVendor, setAllVenodor] = useState([]);
  const allvendorfilter = AllVendor.map((O) => O.piccode);
  // console.log("AllVendor", AllVendor);
  // console.log("AllVendor", AllVendor);

  const [nochVendort, setnochangeVendor] = useState([]);
  const getAllVendorsData = async () => {
    try {
      let res = await axios.get(
        "https://coorgtour.in/api/vendor/getAllVendors"
      );
      if (res.status == 200) {
        setAllVenodor(res.data.success);
        setnochangeVendor(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllJobs();
    getAllVendorsData();
  }, []);
  const [EnterfilenameModal1, setEnterfilenameModal1] = useState(false);
  const [datacommission, setDatacommission] = useState([]);
  // console.log(datacommission);

  const getCommission = async () => {
    try {
      let res = await fetch("https://coorgtour.in/api/admin/getcommission");
      let data = await res.json();
      setDatacommission(data.success);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCommission();
  }, []);

  // console.log(data);
  const customTotal = (from, to, size) => {
    setSlno(from);
    return (
      <></>
      // <span className="react-bootstrap-table-pagination-total">
      //   Showing {from} to {to} of {size} Results
      // </span>
    );
  };

  const {
    filteredData,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    filterData,
  } = useDateFilter(data, "createdAt");

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const assignVendorJobs = async (data) => {
    try {
      const config = {
        url: "/api/admin/assignVendorJobs",
        method: "put",
        baseURL: "https://coorgtour.in",
        headers: { "content-type": "application/json" },
        data: {
          id: editdata?._id,
          vendorName: data?.name,
          vendorMobile: data?.mobile,
          vendorId: data?._id,
          VendorStatus: "Assigned",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        seteditdata(res.data.success);
        getAllJobs();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const assignVendorJobsCancel = async (data) => {
    try {
      const config = {
        url: "/api/admin/assignVendorJobs",
        method: "put",
        baseURL: "https://coorgtour.in",
        headers: { "content-type": "application/json" },
        data: {
          id: editdata?._id,
          vendorName: "",
          vendorMobile: "",
          vendorId: editdata?._id,
          VendorStatus: "Pending",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        seteditdata(res.data.success);
        getAllJobs();
      }
    } catch (error) {
      alert(error.response.data.error);
      console.log(error);
    }
  };
  const abcHandel = (e) => {
    if (e.target.value != "") {
      const filterTable = nochVendort.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAllVenodor([...filterTable]);
    } else {
      setAllVenodor([...nochVendort]);
    }
  };

  // updatecommission

  const [commissionid, setCommissionid] = useState("");
  const [recivedammount, setRecivedammount] = useState("");
  const updatecommission = async (data) => {
    if (!recivedammount) {
      return alert("Amount required !!!");
    }
    if (!commissionid) {
      return alert("Payid required !!!");
    }
    try {
      const config = {
        url: "/api/admin/updatecomission",
        method: "put",
        baseURL: "https://coorgtour.in",
        headers: { "content-type": "application/json" },
        data: {
          id: editdata?._id,
          commissionId: commissionid,
          recivedammount: recivedammount,
          // vendorId: editdata?._id,
          statuscheck: "Completed",
        },
      };
      let res = await axios(config);
      if (res.status == 200) {
        // seteditdata(res.data.success);
        getAllJobs();
        handleClose5();
        setCommissionid(" ");
        setRecivedammount(" ");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      text: "S.No",
      formatter: (value, row, i) => <p>{i + 1}</p>,
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "100px",
      },
    },
    {
      text: "Booking id",
      formatter: (value, row, i) => <p>{row?._id}</p>,
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "250px",
      },
    },
    {
      dataField: "userName",
      text: "UserName",
      formatter: (value, row) => <p>{row.userName ? row.userName : ""}</p>,
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "200px",
      },
    },
    {
      dataField: "userMobile",
      text: "User Mobile",
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "150px",
      },

      // formatter: (value, row) => <p>{row.vendorId ? row.vendorId : ""}</p>,
    },
    {
      dataField: "userEmail",
      text: "User Email",
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "300px",
      },

      // formatter: (value, row) => <p>{row.vendorId ? row.vendorId : ""}</p>,
    },

    {
      text: "Service Details",
      formatter: (value, row) => (
        <p>
          <Table striped bordered hover>
            <tbody>
              {row?.serviceType?.map((ele, i) => {
                return (
                  <tr>
                    <td style={{ width: "20px" }}>{i + 1}</td>
                    <td style={{ width: "220px" }}>
                      <p>{ele?.name}</p>
                    </td>
                    <td style={{ width: "20px" }}>
                      <p>{ele?.quntitity}</p>
                    </td>
                    <td>
                      <p style={{ width: "50px" }}>₹{ele?.price?.toFixed(2)}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </p>
      ),
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "400px",
      },
    },

    {
      dataField: "totalamount",
      text: "Service Amount",
      formatter: (value, row) => (
        // <p>₹{row.TotalAmount ? row.TotalAmount?.toFixed(2) : "0"}</p>
        <p>
          <Table striped bordered hover>
            <tbody>
              {row?.serviceType?.reduce(
                (accumulator, ele) => accumulator + ele?.price,
                0
              )}
            </tbody>
          </Table>
        </p>
      ),
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "150px",
      },
    },
    {
      dataField: "commission",
      text: `Service Commission₹ (-${datacommission[0]?.seramt || 0})`,
      formatter: (value, row) => {
        // Calculate total price from serviceType array
        const commission = datacommission[0]?.seramt
          ? Number(datacommission[0]?.seramt)
          : 0;
        const totalPrice = row?.serviceType?.reduce(
          (accumulator, ele) => accumulator + (ele?.price || 0),
          0
        );
        // const displayAmount = row.servicecommission;
        // Display a message if the commission is not available
        const displayAmount = commission ? totalPrice - commission : totalPrice;
        // const commissionMessage = commission ? `₹ ${displayAmount}` : "Commission not available";

        return (
          <div style={{display:"flex",gap:"10px"}}>
            <p>{totalPrice}</p>-
            <p>{commission}</p>=
            <p>₹{displayAmount}</p>
          </div>
        );
      },
      headerStyle: {
        backgroundColor: "#080874", // Background color for header
        color: "white", // Text color
        width: "180px", // Set column width
      },
    },

    {
      text: "Invoice Amount",
      formatter: (value, row) => (
        // <p>₹{row.TotalAmount ? row.TotalAmount?.toFixed(2) : "0"}</p>
        <p>{row.TotalAmount}</p>
      ),
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "100px",
      },
    },
    {
      dataField: "invoice",
      text: `Product Commission (${datacommission[0]?.productpr || 0}%)`,
      formatter: (value, row) => {
        // Get the commission percentage, defaulting to 0 if not availabl
        return (
          <div>
            {/* <p>₹{totalAmount.toFixed(2)}</p> */}
            <p>₹{row?.TotalAmount-Number(row?.productcommission)}</p>
            {/* <p>₹{(totalAmount - tenPercentCut).toFixed(2)}</p> */}
          </div>
        );
      },
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "150px",
      },
    },
    // {
    //   text: "Remaining Amount",
    //   formatter: (value, row) => (
    //     <p>₹{row.TotalAmount?.toFixed(2) - row?.payAmount?.toFixed(2)}</p>
    //   ),
    //   headerStyle: {
    //     backgroundColor: "#080874",
    //     color: "white",
    //     width: "100px",
    //   },
    // },
    {
      dataField: "paymentStatus",
      text: "Payment Status",
      formatter: (value, row) => (
        <p>{row.paymentStatus ? row.paymentStatus : ""}</p>
      ),
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "100px",
      },
    },
    {
      dataField: "userAddress",
      text: "Address",
      formatter: (value, row) => (
        <p>
          {row.userAddress},{row.pincode}
        </p>
      ),
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "300px",
      },
    },

    {
      dataField: "vendorName",
      text: "VendorName",
      formatter: (value, row) => <p>{row.vendorName ? row.vendorName : ""}</p>,
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "200px",
      },
    },
    {
      dataField: "vendorMobile",
      text: "VendorMobile",
      formatter: (value, row) => (
        <p>{row.vendorMobile ? row.vendorMobile : ""}</p>
      ),
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "170px",
      },
    },
    {
      dataField: "vendorAdvanceAm",
      text: "Advance Amount",
      formatter: (value, row) => <p>{row.vendorAdvanceAm}</p>,
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "170px",
      },
    },
    {
      dataField: "Booking date",
      text: "Serivce Date",
      formatter: (value, row) => <p>{row.serviceDate}</p>,
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "150px",
      },
    },

    {
      dataField: "RequestAmount",
      text: "RequestAmount",
      formatter: (value, row) => (
        <p>
          <span style={{ color: "black", textAlign: "center" }}>
            {row?.RequestAmount}
          </span>
        </p>
      ),
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "150px",
      },
    },

    {
      dataField: "cotision",
      text: "Quotation",
      formatter: (value, row) => (
        <p>
          {row?.cotision == "Pending" ? (
            <span style={{ color: "blue" }}>{row?.cotision}</span>
          ) : (
            <span>
              {row?.cotision == "Approved" ? (
                <span style={{ color: "green" }}>{row?.cotision}</span>
              ) : (
                <span style={{ color: "red" }}>{row?.cotision}</span>
              )}
            </span>
          )}
        </p>
      ),
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "150px",
      },
    },
    {
      dataField: "Status",
      text: "Status",
      formatter: (value, row) => (
        <p>
          {row?.status == "Pending" ? (
            <span style={{ color: "blue" }}>{row?.status}</span>
          ) : (
            <span>
              {row?.status == "Cancel" ? (
                <span style={{ color: "red" }}>{row?.status}</span>
              ) : (
                <span style={{ color: "green" }}>{row?.status}</span>
              )}
            </span>
          )}
        </p>
      ),
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "100px",
      },
    },

    {
      dataField: "notworkingReason",
      text: "notworkingReason",
      formatter: (value, row) => (
        <p>
          <span style={{ color: "blue" }}>{row?.notworkingReason}</span>
        </p>
      ),
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "200px",
      },
    },

    // {
    //   dataField: "recivedammount",
    //   text: "Comission Amount",
    //   formatter: (value, row) => (
    //     <p>
    //       {row?.statuscheck == "Completed" ? (
    //         <p
    //         // className="btn"
    //         >
    //           {row?.recivedammount}
    //         </p>
    //       ) : (
    //         <>
    //           {row?.status == "Completed" ? (
    //             <>
    //               {" "}
    //               <button
    //                 className="btn"
    //                 style={{ color: "white", background: "#080874" }}
    //                 onClick={() => {
    //                   handleShow5();
    //                   seteditdata(row);
    //                 }}
    //               >
    //                 Vendor Comission
    //               </button>
    //             </>
    //           ) : (
    //             <>
    //               <p>00</p>
    //             </>
    //           )}
    //         </>
    //       )}
    //     </p>
    //   ),
    //   headerStyle: {
    //     backgroundColor: "#080874",
    //     color: "white",
    //     width: "200px",
    //   },
    // },
    {
      text: "Vendor Status",
      formatter: (value, row) => (
        <p>
          {row?.VendorStatus == "Pending" ? (
            <span style={{ color: "blue" }}>{row?.VendorStatus}</span>
          ) : (
            <span>
              {row?.VendorStatus == "Cancel" ||
              row?.VendorStatus == "Assigned" ? (
                <span style={{ color: "red" }}>{row?.VendorStatus}</span>
              ) : (
                <span style={{ color: "green" }}>{row?.VendorStatus}</span>
              )}
            </span>
          )}
        </p>
      ),
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "100px",
      },
    },
    {
      text: "Action",
      formatter: (value, row) => (
        <div>
          {row?.status == "Cancel" ||
          row?.status == "Completed" ||
          row?.VendorStatus == "Accepted" ||
          row?.status == "Confirmed" ? (
            <></>
          ) : (
            <button
              className="btn"
              style={{ color: "white", background: "#080874" }}
              onClick={() => {
                seteditdata(row);
                setEnterfilenameModal1(true);
              }}
            >
              Assign Vendor
            </button>
          )}
          {row.vendorId ? (
            <>
              <button
                className="btn"
                style={{
                  color: "white",
                  background: "green",
                  marginTop: "5px",
                }}
                onClick={() => {
                  navigator("/admin/Chat", { state: row });
                }}
              >
                View Chat
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
      headerStyle: {
        backgroundColor: "#080874",
        color: "white",
        width: "100px",
      },
    },
  ];

  const [selectedVendor, setSelectedVendor] = useState("");

  useEffect(() => {}, []);

  // const fildersata = filterData.filter((item) => item?.vendorName == selectedVendor?.name)

  return (
    <div style={{ paddingLeft: "30px" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
          color: "#080874",
        }}
      >
        Booking Service
      </div>

      <Container>
        <div style={{ overflow: "hidden", overflowY: "auto" }}>
          <ToolkitProvider
            keyField="id"
            data={
              selectedVendor
                ? filteredData.filter(
                    (item) => item?.vendorName === selectedVendor
                  )
                : filteredData
            }
            columns={columns}
            search
            exportCSV
          >
            {(props) => (
              <div>
                <div>
                  <div className="row" style={{ alignItems: "end" }}>
                    <div className="col-lg-2 px-2">
                      <label>From :</label>
                      <Form.Control
                        type="date"
                        aria-describedby="basic-addon1"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-2 px-2">
                      <label>To :</label>
                      <Form.Control
                        type="date"
                        aria-describedby="basic-addon1"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <button className="btn btn-primary" onClick={filterData}>
                        Submit
                      </button>
                    </div>
                    <div className="col-lg-6" style={{ marginLeft: "-23px" }}>
                      {/* <Form.Select 
                      onChange={(e)=>setSelectedVendor(e.target.value)}
                      >
                        <option>Open this select menu</option>
                        {AllVendor.map((item) => {
                          return <option value={item?.name}>{item?.name}</option>;
                        })}
                      </Form.Select> */}
                      <Autocomplete
                        disablePortal
                        options={AllVendor.map((vendor) => vendor.name)} // Map to vendor names
                        sx={{ width: 300 }}
                        onChange={(e, value) => setSelectedVendor(value || "")}
                        renderInput={(params) => (
                          <TextField {...params} label="Vendor" />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <hr></hr>
                <br></br>
                <div style={{ overflowX: "scroll" }}>
                  <BootstrapTable
                    striped
                    hover
                    condensed
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </div>
      </Container>

      <Modal
        show={EnterfilenameModal1}
        onHide={() => setEnterfilenameModal1(false)}
        style={{ marginTop: "2%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#080874" }}> Assign Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <div class="position-relative mt-2" style={{ display: "flex" }}>
                <input
                  id="search-bar-0"
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control "
                  onChange={(e) => abcHandel(e)}
                  placeholder="Search Vendor"
                  style={{
                    borderRadius: "50px",
                    border: "1px solid #fdd12d",
                    marginBottom: "5px",
                  }}
                />
              </div>
              {AllVendor.filter(
                (item) =>
                  item?.status == "Approved" &&
                  item?.assignmentHubPincode == editdata.pincode
              )

                // ?.filter((elem) => {
                //   if (elem?.Hub) {
                //     if (pickcode?.includes(JSON.parse(elem?.Hub)?.pincode)) {
                //       return true;
                //     }
                //     return false;
                //   }
                //   return false;
                // })
                ?.map((data1) => {
                  // console.log(data1);
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          margin: "10px",
                          flexWrap: "wrap",
                        }}
                      >
                        {/* <input type="checkbox"/> */}
                        {data1?._id == editdata?.vendorId ? (
                          <BsCheckCircleFill
                            style={{
                              color: "green",
                              textAlign: "center",
                              width: "25px",
                              height: "25px",
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        {data1?.profile ? (
                          <img
                            src={`https://coorgtour.in/Vendor/${data1?.profile}`}
                            alt="#"
                            width="30px"
                            height="30px"
                            style={{ borderRadius: 10 }}
                          />
                        ) : (
                          <FaUserCircle
                            style={{
                              color: "rgb(59 111 124)",
                              textAlign: "center",
                              width: "25px",
                              height: "25px",
                            }}
                          />
                        )}
                        <span style={{ color: "black" }}>{data1?.name} </span>{" "}
                        <span style={{ color: "black" }}>{data1?.mobile} </span>
                      </div>

                      <div className="">
                        {data1?._id == editdata?.vendorId ? (
                          <Button
                            variant=""
                            style={{
                              background: "white",
                              color: "#080874",
                              border: "1px solid #080874",
                            }}
                            onClick={() => assignVendorJobsCancel(data1)}
                          >
                            Cancel{" "}
                          </Button>
                        ) : (
                          <Button
                            variant=""
                            style={{ background: "#080874", color: "white" }}
                            onClick={() => assignVendorJobs(data1)}
                          >
                            Assign{" "}
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title>Vendor Comission Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="position-relative mt-2" style={{ display: "flex" }}>
            <input
              type="text"
              class="form-control "
              placeholder="Enter Amount"
              style={{
                border: "1px solid #fdd12d",
                marginBottom: "5px",
              }}
              value={recivedammount}
              onChange={(e) => setRecivedammount(e.target.value)}
            />
          </div>
          <div class="position-relative mt-2" style={{ display: "flex" }}>
            <input
              class="form-control "
              placeholder="Enter Payid"
              style={{
                border: "1px solid #fdd12d",
                marginBottom: "5px",
              }}
              value={commissionid}
              onChange={(e) => setCommissionid(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={updatecommission}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Order;
