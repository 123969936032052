import React, { useState } from "react";
import axios from "axios";

function GeneratePassword() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [addhar, setAddher] = useState("");
  const [pancard, setPancard] = useState("");
  const [gst, setGst] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [piccode, setPincode] = useState("");

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateName = (name) => {
    const re = /^[A-Za-z\s]+$/;
    return re.test(name);
  };

  const validatePhoneNumber = (number) => {
    const re = /^[6-9]\d{9}$/;
    return re.test(number);
  };

  const handleRegister = async () => {
    if (!validateName(name)) {
      alert("Please enter a valid name (letters and spaces only)");
      return;
    }
    if (!validateEmail(email)) {
      alert("Please Enter Your Email");
      return;
    }
    if (!validatePhoneNumber(mobile)) {
      return alert("Please enter a valid phone number (10 digits)");
    }
    if (!password) {
      alert("Please Enter Your Password !!");
      return;
    }
    if (addhar.length !== 12) {
      return alert("Please Enter Your Aadhar card no 12 digits !!");
    }

    if (pancard.length !== 10) {
      return alert("Please Enter Pancard 10 digits alphanumeric with caps !!");
    }
    if (gst.length !== 15) {
      return alert("Please Enter 15 digits gst alphanumerics with caps !!");
    }
    if (piccode.length !== 6) {
      return alert("Please Enter pin code no 6 digits!!");
    } else {
      try {
        const config = {
          url: "/registerVendor",
          method: "post",
          baseURL: "https://coorgtour.in/api/vendor",
          // headers: { "content-type": "application/json" },
          data: {
            name: name,
            email: email,
            mobile: mobile,
            password: password,
            area: area,
            city: city,
            state: state,
            piccode: piccode,
            addhar: addhar,
            pancard: pancard,
            gst: gst,
          },
        };

        let res = await axios(config);
        if (res.status === 200) {
          // console.log(res.data.success);
          alert("Vendor register successfully please login !!!");
          window.location.reload(true);
          window.location.assign('/admin/Prorequest')
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.error);
        }
      }
    }
  };

  return (
    <div>
      <div className="container p-5">
        <div className="">Generate Password for Pro Request</div>
        <div className="row">
          <div className="col-md-6 py-3 px-2">
            <label>Name</label>
            <input
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6 py-3 px-2">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6 py-3 px-2">
            <label>Phone Number</label>
            <input
              type="number"
              placeholder="Enter phone number"
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6 py-3 px-2">
            <label>Password</label>
            <input
              type="text"
              placeholder="Enter password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6 py-3 px-2">
            <label>Area</label>
            <input
              type="text"
              placeholder="Enter area"
              value={area}
              onChange={(e) => {
                setArea(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6 py-3 px-2">
            <label>City</label>
            <input
              type="text"
              placeholder="Enter city"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6 py-3 px-2">
            <label>State</label>
            <input
              type="text"
              placeholder="Enter state"
              value={state}
              onChange={(e) => {
                setState(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6 py-3 px-2">
            <label>Pincode</label>
            <input
              type="number"
              placeholder="Enter pincode"
              value={piccode}
              onChange={(e) => {
                setPincode(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6 py-3 px-2">
            <label>Aadharcard No</label>
            <input
              type="number"
              placeholder="Enter aadharcard no"
              value={addhar}
              onChange={(e) => {
                setAddher(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6 py-3 px-2">
            <label>Gst</label>
            <input
              type="text"
              placeholder="Enter gst no"
              value={gst}
              onChange={(e) => {
                setGst(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6 py-3 px-2">
            <label>Pancard No</label>
            <input
              type="text"
              placeholder="Enter pancard no"
              value={pancard}
              onChange={(e) => {
                setPancard(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          style={{
            background: "#080874",
            color: "white",
            width: "15%",
            textAlign: "center",
            padding: "10px",
            cursor: "pointer",
          }}
          onClick={handleRegister}
        >
          Submit
        </div>
      </div>
    </div>
  );
}

export default GeneratePassword;
