import React, { useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";

import { FaSearch } from "react-icons/fa";
import { Table } from "react-bootstrap";
import axios from "axios";

const Video = () => {
  const [name, setName] = useState("");
  const [name1, setName1] = useState("");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [editData, setEditData] = useState({});
  const [update, setUpdate] = useState({});

  const handleClose = () => setShow(false);
  const handleEditClose = () => setEditShow(false);
  const handleEditShow = () => setEditShow(true);
 console.log(update,"update")

  const getCreatehub = () => {
    axios
      .get("https://coorgtour.in/api/admin/getvideo")
      .then(function (response) {
        setData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getCreatehub();
  }, []);
  const [video, setVideo] = useState("");
  const handleFileChange = (e) => {
    setVideo(e.target.files[0]);
  };
  const handleFileChange1 = (e) => {
    setVideo(e.target.files[0]);
  };
  const addCreatehub = async () => {
    if (!video) {
      return alert("Video required");
    }
    try {
      const config = {
        url: "/createvideo",
        method: "post",
        baseURL: "https://coorgtour.in/api/admin",
        headers: { "Content-Type": "multipart/form-data" },
        data: { video },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          alert("Video Added");
          handleClose();
          setVideo("");
          getCreatehub();
        }
      });
    } catch (error) {
      console.log(error);
      alert("Unable to add Details");
    }
  };

  const editCreatehub = async () => {
    try {
      const formData = new FormData();
      formData.append('id', update?._id); 
      formData.append('video', video); 
  
      const config = {
        url: `/updatevideo/${update?._id}`, 
        method: "put",
        baseURL: "https://coorgtour.in/api/admin",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData, 
      };
  
      const res = await axios(config);
      if (res.status === 200) {
        alert("Video Updated");
        handleEditClose();
        setVideo(""); // Clear video state
        getCreatehub(); // Refresh the list
      }
    } catch (error) {
      console.log(error);
      alert("Unable to update Details");
    }
  };
  

  const deleteCreatehub = async (id) => {
    axios({
      method: "post",
      url: `https://coorgtour.in/api/admin/deleteCreatehub/${id}`,
    })
      .then(function (response) {
        alert(response.data.Success);
        getCreatehub();
      })
      .catch(function (error) {
        console.log(error.response.data);
      });
  };

  return (
    <div style={{ paddingLeft: "30px" }}>
      <div
        style={{
          backgroundColor: "white",
          fontWeight: 600,
          padding: "22px",
          fontSize: "22px",
          color: "#080874",
        }}
      >
        Training Video
      </div>

{
  data.length < 5 ? (<Button
    variant=""
    onClick={() => setShow(true)}
    style={{
      right: "20px",
      position: "absolute",
      background: "#080874",
      color: "white",
      top: "105px",
    }}
  >
    + Create Video
  </Button>):(<></>)
}
     
     
      <Container>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S,No.</th>
              <div style={{ width: "200px" }}>
                {" "}
                <th>Video</th>
              </div>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {data.map((person, i) => (
              <tr key={person.id}>
                <td>{++i}</td>
                <td>
                  <video width="320" height="240" controls autoplay>
                    <source
                      src={`https://coorgtour.in/Video/${person?.video}`}
                      type="video/mp4"
                    />
                  </video>
                </td>
                <td>
                  <button
                    style={{ border: "none", backgroundColor: "transparent" }}
                    onClick={() => {
                        setUpdate(person);
                        handleEditShow(person);
                    }}
                  >
                    <i
                      className="fas fa-pen-nib"
                      style={{ color: "#3B707F" }}
                    ></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* Add Modal */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title
              style={{ fontWeight: 600, fontSize: "22px", color: "#080874" }}
            >
              Create Video
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="urban-o mb-2">
              <label>Video</label>
              <br />
              <input type="file" accept="video/*" onChange={handleFileChange} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              style={{ border: "1px solid #080874", color: "#080874" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant=""
              onClick={addCreatehub}
              style={{ background: "#080874", color: "white" }}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Edit Modal */}
        <Modal show={editShow} onHide={handleEditClose}>
          <Modal.Header closeButton>
            <Modal.Title
              style={{ fontWeight: 600, fontSize: "22px", color: "#080874" }}
            >
              Edit Video
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="urban-o mb-2">
              <label>Video</label>
              <br />
              <input type="file" accept="video/*" onChange={handleFileChange1} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              onClick={handleEditClose}
              style={{ border: "1px solid #080874", color: "#080874" }}
            >
              Cancel
            </Button>
            <Button
              variant=""
              onClick={() => editCreatehub(editData)}
              style={{ background: "#080874", color: "white" }}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default Video;
